.messageBox {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid transparent;
    border-radius: 3px;
    p {
        margin: 0;
    }
}
.successBox {
    color: #323e4d;
    background-color: #effff6;
    border-color: #00e682;
}
.infoBox {
    color: #323e4d;
    background-color: #d6f5f5;
    border-color: #33cccc;
}
.warningBox {
    color: #323e4d;
    background-color: #ffe0b3;
    border-color: #ffa22b;
}
.dangerBox {
    color: #323e4d;
    background-color: #fee6e6;
    border-color: #fb9d9d;
}
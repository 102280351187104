.nav-bar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 1px solid #e7e7e7;
    background-color: #f3f3f3;
}
.nav-bar li a {
    display: block;
    color: #666;
    text-align: center;
    padding: 8px 12px;
    text-decoration: none;
    font-size: 0.9rem;
}
.nav-bar li a:hover:not(.active) {
    background-color: #ddd;
}
.nav-bar li a.active {
    color: white;
    background-color: #00a4b6;
}
@media screen and (min-width:770px) {
    .nav-bar li {
        float: left;
    }
}